import Vue from 'vue';
import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  activeTool: '',
  basemap: localStorage.getItem('basemap') || 'daylight',
  filtersItems: { district: {}, street: [] },
  filtersValues: {},
  identificationCols: 0,
  isSidebarVisible: true,
  project: { id: null, layers: [], name: '' },
  selectedLuminareSymbolizationLayer: null,
  selectedMap: null,
};

const mutations = {
  ...make.mutations(state),
  SET_LAYER_VISIBILITY: (state, { layersIds = [], value = false } = {}) => {
    layersIds.forEach(layerId => {
      const layer = state.project.layers.find(l => l.layer_id === layerId);
      if (layer) Vue.set(layer, 'visible', value);
      else {
        state.project.layers
          .filter(element => element.type === 'group')
          .forEach(group => {
            const groupLayer = group.layers.find(l => l.layer_id === layerId);
            if (groupLayer) Vue.set(groupLayer, 'visible', value);
          });
      }
    });
  },
};

const actions = {
  async getFiltersUniquesValues({ commit }) {
    const r = await api.get('attributes/unique_values');
    const streetsSet = Object.values(r.data.data).reduce((total, current) => {
      return new Set([...total, ...current]);
    }, new Set());
    const attributesValues = {
      district: r.data.data,
      street: Array.from(streetsSet),
    };
    commit('SET_FILTERS_ITEMS', Object.freeze(attributesValues));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
