// import { asArray, asString } from 'ol/color';

const arrayToObject = (array = [], key = 'id', value) => {
  return array.reduce((total, current) => {
    return { ...total, [current[key]]: value ? current[value] : current };
  }, {});
};

const objectToArray = (object = {}, keyPropName) => {
  return Object.keys(object).map(key => {
    return keyPropName ? { value: object[key], [keyPropName]: key } : object[key];
  });
};

const capitalize = string => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const filterObjectKeys = (obj = {}, { allowedKeys, notAllowedKeys } = {}) => {
  return Object.keys(obj)
    .filter(key => {
      return (!allowedKeys || allowedKeys.includes(key)) && !(notAllowedKeys && notAllowedKeys.includes(key));
    })
    .reduce((rObj, key) => {
      rObj[key] = obj[key];
      return rObj;
    }, {});
};

const flattenStructure = (elements, childKey = 'items', maxLevel) => {
  const flatten = (elms, level = 0) => {
    level = level + 1;
    return elms.reduce((total, current) => {
      if (current[childKey] && (!maxLevel || level < maxLevel)) {
        return [...total, ...flatten(current[childKey], level)];
      } else {
        return [...total, { ...current }];
      }
    }, []);
  };
  return flatten(elements);
};

const getHash = string => {
  let hash = 0,
    i,
    chr;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};

const defaultHashGenerator = object => {
  return getHash(
    objectToArray(object)
      .map(value => {
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
          return objectToArray(value);
        }
        return value;
      })
      .join('')
  );
};

const importAll = (r, cacheObject = {}) => {
  return r.keys().reduce((total, current) => {
    return { ...total, [current]: r(current) };
  }, cacheObject);
};

const memoizer = (f, { cacheObject, hashGenerator = defaultHashGenerator } = {}) => {
  const cache = cacheObject || {};
  return (argumentsObject = {}) => {
    const hashedArguments = hashGenerator(argumentsObject);
    if (cache[hashedArguments]) {
      return cache[hashedArguments];
    } else {
      const result = f(argumentsObject);
      cache[hashedArguments] = result;
      return result;
    }
  };
};

const sortObjectsByAttribute = (array, attribute, exclude, number = false) => {
  return array.sort((a, b) => {
    if (exclude && a[exclude.name] === exclude.value) {
      return -1;
    } else if (exclude && b[exclude.name] === exclude.value) {
      return 1;
    } else {
      return number
        ? b[attribute] - a[attribute]
        : a[attribute]?.toString().toLowerCase().localeCompare(b[attribute]?.toString().toLowerCase());
    }
  });
};

const base64ToFile = (base64 = '', { name = 'file', autoExtention = false }) => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) u8arr[n] = bstr.charCodeAt(n);
  let fileName = name;
  if (autoExtention) fileName = `${fileName}.${mime.split('/')[1]}`;
  return new File([u8arr], fileName, { type: mime });
};

const base64ToUrl = (base64 = '', { name = 'file', autoExtention = false }) => {
  const file = base64ToFile(base64, { name, autoExtention });
  return URL.createObjectURL(file);
};

// const summarizeArrayOfObjectsKey = (array = [], keyName = 'value') => {
//   return array.reduce((total, current) => {
//     return total + (current[keyName] || 0);
//   }, 0);
// };

// const saveFile = (blobUrl, name) => {
//   const link = document.createElement('a');
//   link.setAttribute('href', blobUrl);
//   link.setAttribute('download', name);
//   document.body.appendChild(link);
//   link.click();
// };

// const saveFileFromBackend = (data, headers, name) => {
//   const [type] = headers['content-type'].split(';');
//   const blob = new Blob([data], { type });
//   saveFile(URL.createObjectURL(blob), name);
// };

// const isHexColorLight = color => {
//   return getHexColorBrightness(color) > 155;
// };

// const getHexColorBrightness = color => {
//   const hex = color.replace('#', '');
//   const c_r = parseInt(hex.substr(0, 2), 16);
//   const c_g = parseInt(hex.substr(2, 2), 16);
//   const c_b = parseInt(hex.substr(4, 2), 16);
//   return (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
// };

// const hexToRgba = (hex, opacity) => {
//   const [r, g, b] = Array.from(asArray(hex));
//   return asString([r, g, b, opacity]);
// };

export {
  arrayToObject,
  objectToArray,
  capitalize,
  filterObjectKeys,
  flattenStructure,
  importAll,
  memoizer,
  sortObjectsByAttribute,
  base64ToUrl,
};
