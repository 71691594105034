import { Point, LineString, Polygon } from 'ol/geom';
import { getCenter } from 'ol/extent';

const getGeometryCentroid = geometry => {
  if (geometry instanceof Point) {
    return geometry.getCoordinates();
  } else if (geometry instanceof LineString) {
    return geometry.getCoordinateAt(0.5);
  } else if (geometry instanceof Polygon) {
    return geometry.getInteriorPoint().getCoordinates().splice(0, 2);
  } else {
    return getCenter(geometry.getExtent());
  }
};

export { getGeometryCentroid };
